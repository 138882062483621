import * as React from 'react';
import { SVGProps } from 'react';

const SvgApiIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={305}
      height={217}
      viewBox="0 0 305 217"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M288.149 45.307a4.921 4.921 0 0 0-1.513-1.494 5.04 5.04 0 0 0-2.016-.729l-21.502-2.902-7.724-19.45a4.894 4.894 0 0 0-1.205-1.767 4.99 4.99 0 0 0-1.852-1.096 71.431 71.431 0 0 0-26.411-3.424 5.184 5.184 0 0 0-3.684 1.846l-13.957 16.751-21.64-2.748a5.184 5.184 0 0 0-3.961 1.159 71.58 71.58 0 0 0-16.958 20.563 5.017 5.017 0 0 0-.61 2.063c-.05.72.059 1.441.319 2.112l7.572 19.53-13.763 16.794a5.038 5.038 0 0 0-.979 1.91 4.924 4.924 0 0 0-.091 2.125 64.95 64.95 0 0 0 9.461 24.077 4.91 4.91 0 0 0 1.513 1.493 5.023 5.023 0 0 0 2.015.729l21.503 2.903 7.726 19.455a4.89 4.89 0 0 0 1.205 1.767 4.983 4.983 0 0 0 1.852 1.096 71.423 71.423 0 0 0 26.411 3.424 5.174 5.174 0 0 0 3.684-1.846l13.963-16.787 21.64 2.748a5.104 5.104 0 0 0 2.533-.329 5.234 5.234 0 0 0 1.424-.841 71.705 71.705 0 0 0 16.962-20.551 5 5 0 0 0 .61-2.063 4.907 4.907 0 0 0-.319-2.113l-7.571-19.53 13.762-16.794a5.04 5.04 0 0 0 .979-1.909 4.924 4.924 0 0 0 .091-2.125 64.945 64.945 0 0 0-9.469-24.047Zm-51.147 60.818a25.77 25.77 0 0 1-14.671 1.269 24.977 24.977 0 0 1-12.795-6.969 24.425 24.425 0 0 1-6.606-12.859 24.766 24.766 0 0 1 1.809-14.414 25.605 25.605 0 0 1 9.614-11.111 25.892 25.892 0 0 1 14.179-4.063 25.28 25.28 0 0 1 13.965 4.355 24.525 24.525 0 0 1 9.043 11.304c2.415 6.146 2.24 13.022-.486 19.114-2.727 6.093-7.781 10.904-14.052 13.374Z"
        fill="#C3DAFE"
      />
      <ellipse
        cx={155.858}
        cy={201.298}
        rx={135.625}
        ry={6.853}
        fill="#C3DAFE"
      />
      <g opacity={0.5}>
        <rect
          x={111.031}
          y={23.699}
          width={63.951}
          height={45.718}
          rx={4.303}
          fill="#D9D9D9"
        />
        <rect
          x={109.955}
          y={22.623}
          width={66.103}
          height={47.869}
          rx={5.379}
          stroke="#fff"
          strokeOpacity={0.3}
          strokeWidth={2.151}
        />
        <path
          d="M111.031 28.002a4.302 4.302 0 0 1 4.302-4.303h55.346a4.303 4.303 0 0 1 4.303 4.303v6.454h-63.951v-6.454Z"
          fill="#C9C9C9"
        />
        <rect
          x={166.384}
          y={26.926}
          width={4.299}
          height={4.303}
          rx={2.15}
          fill="#EEE"
        />
        <rect
          x={158.323}
          y={26.926}
          width={4.299}
          height={4.303}
          rx={2.15}
          fill="#EEE"
        />
        <rect
          x={150.261}
          y={26.926}
          width={4.299}
          height={4.303}
          rx={2.15}
          fill="#EEE"
        />
        <path fill="#C8C8C8" d="M115.329 38.762h9.673v2.151h-9.673z" />
        <path fill="#fff" d="M115.329 43.063h9.673v2.151h-9.673z" />
        <path
          fill="#C8C8C8"
          d="M115.329 49.516h9.673v2.151h-9.673zM161.547 38.762h9.673v2.151h-9.673z"
        />
        <path fill="#fff" d="M161.547 58.121h9.673v2.151h-9.673z" />
        <path
          fill="#CCC"
          d="M128.227 38.762h31.707v2.151h-31.707zM128.227 58.121h31.707v2.151h-31.707z"
        />
        <path fill="#C8C8C8" d="M128.227 43.063h16.66v2.151h-16.66z" />
        <path fill="#CCC" d="M128.227 49.516h20.421v2.151h-20.421z" />
        <path fill="#fff" d="M128.227 53.82h12.898v2.151h-12.898z" />
        <path fill="#C8C8C8" d="M128.227 62.426h12.898v2.151h-12.898z" />
        <path fill="#fff" d="M147.037 43.063h16.66v2.151h-16.66z" />
        <path
          fill="#C8C8C8"
          d="M149.724 49.516h20.959v2.151h-20.959zM143.275 53.82h12.898v2.151h-12.898z"
        />
        <path fill="#fff" d="M143.275 62.426h12.898v2.151h-12.898z" />
        <path fill="#CCC" d="M158.323 53.82h12.898v2.151h-12.898z" />
      </g>
      <g filter="url(#a)">
        <path fill="url(#b)" d="M111.031 48.828h63.958v4.568h-63.958z" />
      </g>
      <path
        d="M86.888 127.822a3.26 3.26 0 0 0-1-.987 3.339 3.339 0 0 0-1.332-.481l-14.21-1.92-5.106-12.849a3.235 3.235 0 0 0-.796-1.168 3.284 3.284 0 0 0-1.224-.724 47.207 47.207 0 0 0-17.453-2.265 3.428 3.428 0 0 0-2.434 1.219l-9.222 11.065-14.3-1.818a3.413 3.413 0 0 0-2.617.766A47.27 47.27 0 0 0 5.99 132.242c-.232.422-.37.887-.403 1.363a3.24 3.24 0 0 0 .211 1.396l5.005 12.902-9.093 11.094a3.338 3.338 0 0 0-.646 1.261c-.122.46-.143.939-.06 1.404a42.916 42.916 0 0 0 6.254 15.907 3.25 3.25 0 0 0 1 .987 3.34 3.34 0 0 0 1.332.482l14.21 1.919 5.107 12.854c.176.442.447.841.796 1.167a3.3 3.3 0 0 0 1.224.725 47.227 47.227 0 0 0 17.453 2.264 3.411 3.411 0 0 0 2.434-1.219l9.226-11.089 14.3 1.818a3.373 3.373 0 0 0 1.674-.217c.341-.134.659-.322.94-.556a47.347 47.347 0 0 0 11.208-13.575c.232-.421.37-.886.402-1.363a3.233 3.233 0 0 0-.21-1.395l-5.006-12.903 9.093-11.094a3.34 3.34 0 0 0 .647-1.26 3.26 3.26 0 0 0 .06-1.405 42.921 42.921 0 0 0-6.26-15.887Zm-33.793 40.174a17.043 17.043 0 0 1-9.694.837 16.52 16.52 0 0 1-8.456-4.606 16.138 16.138 0 0 1-4.367-8.495 16.362 16.362 0 0 1 1.194-9.523 16.912 16.912 0 0 1 6.352-7.339 17.117 17.117 0 0 1 9.37-2.683 16.711 16.711 0 0 1 9.228 2.879 16.204 16.204 0 0 1 5.977 7.469c1.597 4.06 1.482 8.602-.32 12.627-1.8 4.025-5.14 7.202-9.284 8.834Z"
        fill="url(#c)"
      />
      <rect
        x={65.921}
        y={52.535}
        width={175.089}
        height={116.824}
        rx={5.593}
        fill="#D1D5DB"
        stroke="url(#d)"
        strokeWidth={1.243}
      />
      <rect
        x={72.75}
        y={59.371}
        width={161.43}
        height={103.153}
        rx={1.243}
        fill="#EFF6FF"
      />
      <rect
        x={72.75}
        y={59.371}
        width={161.43}
        height={103.153}
        rx={1.243}
        fill="url(#e)"
        fillOpacity={0.74}
      />
      <path
        d="M72.75 60.614c0-.686.556-1.243 1.243-1.243h158.944c.687 0 1.243.556 1.243 1.243v13.67H72.75v-13.67Z"
        fill="#3B82F6"
      />
      <path
        d="M128.63 169.98h49.05l2.483 24.235h-54.017l2.484-24.235Z"
        fill="#D1D5DB"
      />
      <path
        d="M128.63 169.98h49.05l.621 7.457H128.01l.62-7.457Z"
        fill="url(#f)"
      />
      <path
        d="M115.592 189.866a4.97 4.97 0 0 1 4.971-4.971h65.805a4.971 4.971 0 0 1 4.972 4.971v4.35h-75.748v-4.35Z"
        fill="url(#g)"
      />
      <rect
        x={221.762}
        y={64.34}
        width={4.967}
        height={4.971}
        rx={2.484}
        fill="#BFDBFE"
      />
      <rect
        x={212.45}
        y={64.34}
        width={4.967}
        height={4.971}
        rx={2.484}
        fill="#BFDBFE"
      />
      <rect
        x={203.136}
        y={64.34}
        width={4.967}
        height={4.971}
        rx={2.484}
        fill="#BFDBFE"
      />
      <path
        d="M184.432 110.424a2.284 2.284 0 0 0-1.105-1.535l-8.468-4.832-.034-9.557a2.277 2.277 0 0 0-.804-1.728 31.748 31.748 0 0 0-10.424-5.875 2.269 2.269 0 0 0-1.834.167l-8.542 4.781-8.551-4.79a2.269 2.269 0 0 0-1.836-.17 31.751 31.751 0 0 0-10.413 5.898 2.272 2.272 0 0 0-.804 1.726l-.042 9.565-8.469 4.832a2.284 2.284 0 0 0-1.104 1.535 30.3 30.3 0 0 0 0 11.813 2.276 2.276 0 0 0 1.104 1.535l8.469 4.833.034 9.559a2.268 2.268 0 0 0 .803 1.728 31.758 31.758 0 0 0 10.424 5.876 2.282 2.282 0 0 0 1.834-.168l8.551-4.795 8.551 4.789c.338.189.719.287 1.107.284.248 0 .494-.04.729-.119a31.8 31.8 0 0 0 10.413-5.892 2.278 2.278 0 0 0 .804-1.726l.042-9.565 8.469-4.832a2.284 2.284 0 0 0 1.104-1.535 30.302 30.302 0 0 0-.008-11.802Zm-31.211 17.279c-2.246 0-4.441-.666-6.309-1.916a11.365 11.365 0 0 1-4.182-5.102 11.383 11.383 0 0 1 2.461-12.391 11.36 11.36 0 0 1 5.815-3.112 11.337 11.337 0 0 1 6.561.647 11.367 11.367 0 0 1 7.009 10.504c0 3.016-1.196 5.908-3.325 8.04a11.346 11.346 0 0 1-8.03 3.33Z"
        fill="#3B82F6"
      />
      <path
        d="M211.674 91.769a.965.965 0 0 0-.464-.646l-3.557-2.033-.014-4.02a.957.957 0 0 0-.337-.727 13.333 13.333 0 0 0-4.378-2.472.954.954 0 0 0-.771.071l-3.587 2.011-3.592-2.015a.95.95 0 0 0-.771-.071 13.337 13.337 0 0 0-4.373 2.48.954.954 0 0 0-.338.726l-.018 4.024-3.556 2.033a.954.954 0 0 0-.464.646 12.748 12.748 0 0 0 0 4.97.95.95 0 0 0 .464.645l3.556 2.033.015 4.021a.956.956 0 0 0 .337.727 13.335 13.335 0 0 0 4.378 2.472.949.949 0 0 0 .77-.071l3.592-2.017 3.591 2.015a.962.962 0 0 0 .771.069 13.335 13.335 0 0 0 4.374-2.479.952.952 0 0 0 .337-.725l.018-4.024 3.557-2.033a.955.955 0 0 0 .463-.645c.324-1.64.323-3.327-.003-4.965Zm-13.108 7.269c-.944 0-1.866-.28-2.65-.806a4.784 4.784 0 0 1-2.028-4.91 4.787 4.787 0 0 1 1.305-2.45 4.766 4.766 0 0 1 5.198-1.036 4.773 4.773 0 0 1 2.14 1.761 4.793 4.793 0 0 1-.593 6.04 4.76 4.76 0 0 1-3.372 1.4Z"
        fill="#C3DAFE"
      />
      <path
        d="M203.064 139.907a.614.614 0 0 0-.302-.42l-2.316-1.322-.009-2.613a.616.616 0 0 0-.22-.472 8.67 8.67 0 0 0-2.851-1.607.613.613 0 0 0-.501.046l-2.336 1.307-2.339-1.309a.61.61 0 0 0-.502-.047 8.69 8.69 0 0 0-2.848 1.613.63.63 0 0 0-.22.472l-.011 2.615-2.316 1.321a.631.631 0 0 0-.302.42 8.281 8.281 0 0 0 0 3.23.614.614 0 0 0 .302.42l2.316 1.321.009 2.614a.62.62 0 0 0 .22.473 8.685 8.685 0 0 0 2.851 1.606.61.61 0 0 0 .501-.046l2.339-1.311 2.338 1.31a.619.619 0 0 0 .502.045 8.702 8.702 0 0 0 2.848-1.611.622.622 0 0 0 .22-.472l.012-2.615 2.316-1.322a.633.633 0 0 0 .302-.419 8.285 8.285 0 0 0-.003-3.227Zm-8.535 4.724a3.101 3.101 0 0 1-2.869-1.919 3.108 3.108 0 0 1 4.057-4.062 3.102 3.102 0 0 1 1.917 2.872 3.106 3.106 0 0 1-3.105 3.109Z"
        fill="#E6EDF8"
      />
      <path
        d="M278.531 59.902a.691.691 0 0 0-.486-.305l-2.956-.4-1.062-2.674a.673.673 0 0 0-.421-.394 9.82 9.82 0 0 0-3.631-.47.714.714 0 0 0-.507.254l-1.919 2.303-2.975-.378a.711.711 0 0 0-.545.16 9.844 9.844 0 0 0-2.332 2.827.692.692 0 0 0-.04.574l1.041 2.685-1.892 2.31a.687.687 0 0 0-.147.555 8.945 8.945 0 0 0 1.301 3.31.694.694 0 0 0 .485.306l2.957.398 1.062 2.676a.675.675 0 0 0 .42.393 9.824 9.824 0 0 0 3.632.471.717.717 0 0 0 .506-.254l1.92-2.308 2.976.378a.729.729 0 0 0 .544-.16 9.86 9.86 0 0 0 2.332-2.827.679.679 0 0 0 .04-.574l-1.041-2.685 1.893-2.31a.693.693 0 0 0 .147-.554 8.945 8.945 0 0 0-1.302-3.307Zm-7.033 8.363a3.543 3.543 0 0 1-2.017.174 3.435 3.435 0 0 1-1.76-.958 3.36 3.36 0 0 1-.908-1.768 3.406 3.406 0 0 1 .249-1.982 3.513 3.513 0 0 1 1.322-1.528 3.557 3.557 0 0 1 1.949-.559 3.475 3.475 0 0 1 1.92.6 3.37 3.37 0 0 1 1.177 4.182 3.535 3.535 0 0 1-1.932 1.839Z"
        fill="#fff"
      />
      <path
        d="M116.638 134.683a.97.97 0 0 0-.464-.646l-3.557-2.033-.014-4.02a.953.953 0 0 0-.337-.727 13.337 13.337 0 0 0-4.379-2.471.948.948 0 0 0-.77.07l-3.587 2.011-3.592-2.014a.937.937 0 0 0-.378-.118.938.938 0 0 0-.393.046 13.343 13.343 0 0 0-4.374 2.481.947.947 0 0 0-.337.726l-.018 4.023-3.556 2.033a.96.96 0 0 0-.464.646 12.765 12.765 0 0 0 0 4.969.957.957 0 0 0 .463.646l3.557 2.033.014 4.021a.959.959 0 0 0 .338.727 13.318 13.318 0 0 0 4.378 2.472.95.95 0 0 0 .77-.071l3.592-2.017 3.591 2.015a.945.945 0 0 0 .771.069 13.35 13.35 0 0 0 4.374-2.478.978.978 0 0 0 .248-.326.972.972 0 0 0 .089-.4l.018-4.024 3.557-2.033a.96.96 0 0 0 .463-.645c.324-1.64.323-3.327-.003-4.965Zm-13.108 7.269a4.763 4.763 0 0 1-4.407-2.953 4.796 4.796 0 0 1 1.034-5.212 4.761 4.761 0 0 1 7.338.724 4.797 4.797 0 0 1-.593 6.04 4.764 4.764 0 0 1-3.372 1.401Z"
        fill="#C3DAFE"
      />
      <ellipse
        cx={153.465}
        cy={116.539}
        rx={14.901}
        ry={14.914}
        fill="#3B82F6"
      />
      <path
        d="m135.663 122.753 6.562-14.019h2.883l6.562 14.019h-3.659l-1.213-2.843h-6.263l-1.213 2.843h-3.659Zm7.974-10.201-1.909 4.554h3.857l-1.909-4.554h-.039ZM152.519 122.753v-14.019h6.741c1.591 0 2.817.411 3.679 1.233.875.809 1.312 1.929 1.312 3.361 0 1.431-.437 2.558-1.312 3.38-.862.809-2.088 1.213-3.679 1.213h-3.082v4.832h-3.659Zm3.659-7.636h2.446c1.432 0 2.147-.596 2.147-1.789s-.715-1.79-2.147-1.79h-2.446v3.579ZM165.957 122.753v-14.019h3.659v14.019h-3.659Z"
        fill="#fff"
      />
      <rect
        x={19.662}
        y={45.968}
        width={75.028}
        height={53.961}
        rx={5.545}
        stroke="url(#h)"
        strokeWidth={1.142}
      />
      <rect
        x={20.233}
        y={46.539}
        width={73.885}
        height={52.819}
        rx={4.971}
        fill="#BFDBFE"
      />
      <path
        d="M20.233 51.51a4.971 4.971 0 0 1 4.97-4.97h63.944a4.971 4.971 0 0 1 4.971 4.97v7.457H20.233V51.51Z"
        fill="#3B82F6"
      />
      <rect
        x={84.184}
        y={50.27}
        width={4.967}
        height={4.971}
        rx={2.484}
        fill="#BFDBFE"
      />
      <rect
        x={74.87}
        y={50.27}
        width={4.967}
        height={4.971}
        rx={2.484}
        fill="#BFDBFE"
      />
      <rect
        x={65.558}
        y={50.27}
        width={4.967}
        height={4.971}
        rx={2.484}
        fill="#BFDBFE"
      />
      <path
        d="M73.345 75.085c-.238-6.164-5.348-11.089-11.501-11.089-2.14 0-4.239.598-6.06 1.726a11.549 11.549 0 0 0-5.466 9.784 1.074 1.074 0 0 1-.973 1.085 1.047 1.047 0 0 1-1.122-1.05 13.628 13.628 0 0 1 .7-4.319.526.526 0 0 0-.622-.68 8.387 8.387 0 0 0-4.578 2.978 8.42 8.42 0 0 0-1.782 5.17c0 4.616 3.894 8.396 8.508 8.396h11.387a11.505 11.505 0 0 0 8.3-3.548 11.56 11.56 0 0 0 3.21-8.453Zm-7.627 5.397L61.53 84.68a1.046 1.046 0 0 1-1.482 0l-4.189-4.198a1.051 1.051 0 0 1 .741-1.793c.278 0 .545.11.741.308l2.4 2.407v-7.962a1.05 1.05 0 0 1 1.048-1.05 1.046 1.046 0 0 1 1.047 1.05v7.962l2.4-2.407a1.047 1.047 0 0 1 1.789.742c0 .279-.11.546-.307.743Z"
        fill="#fff"
      />
      <rect
        x={217.246}
        y={101.359}
        width={73.885}
        height={52.819}
        rx={4.971}
        fill="#BFDBFE"
      />
      <path
        d="M217.246 106.331a4.972 4.972 0 0 1 4.972-4.972h63.943a4.972 4.972 0 0 1 4.971 4.972v7.456h-73.886v-7.456Z"
        fill="#3B82F6"
      />
      <rect
        x={281.198}
        y={105.09}
        width={4.967}
        height={4.971}
        rx={2.484}
        fill="#BFDBFE"
      />
      <rect
        x={271.884}
        y={105.09}
        width={4.967}
        height={4.971}
        rx={2.484}
        fill="#BFDBFE"
      />
      <rect
        x={262.572}
        y={105.09}
        width={4.967}
        height={4.971}
        rx={2.484}
        fill="#BFDBFE"
      />
      <path fill="#3B82F6" d="M222.214 118.758h11.176v2.486h-11.176z" />
      <path fill="#fff" d="M222.214 123.73h11.176v2.486h-11.176z" />
      <path
        fill="#3B82F6"
        d="M222.214 131.188h11.176v2.486h-11.176zM275.61 118.758h11.176v2.486H275.61z"
      />
      <path fill="#fff" d="M275.61 141.129h11.176v2.486H275.61z" />
      <path
        fill="#393939"
        d="M237.115 118.758h36.632v2.486h-36.632zM237.115 141.129h36.632v2.486h-36.632z"
      />
      <path fill="#3B82F6" d="M237.115 123.73h19.247v2.486h-19.247z" />
      <path fill="#393939" d="M237.115 131.188h23.594v2.486h-23.594z" />
      <path fill="#fff" d="M237.115 136.16h14.901v2.486h-14.901z" />
      <path fill="#3B82F6" d="M237.115 146.102h14.901v2.486h-14.901z" />
      <path fill="#fff" d="M258.846 123.73h19.247v2.486h-19.247z" />
      <path
        fill="#3B82F6"
        d="M261.95 131.188h24.215v2.486H261.95zM254.499 136.16H269.4v2.486h-14.901z"
      />
      <path fill="#fff" d="M254.499 146.102H269.4v2.486h-14.901z" />
      <path fill="#393939" d="M271.884 136.16h14.901v2.486h-14.901z" />
      <rect
        x={216.676}
        y={100.788}
        width={75.028}
        height={53.961}
        rx={5.545}
        stroke="url(#i)"
        strokeWidth={1.142}
      />
      <defs>
        <linearGradient
          id="b"
          x1={142.799}
          y1={41.552}
          x2={143.035}
          y2={53.26}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#D1D5DB" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={-5.412}
          y1={131.3}
          x2={47.239}
          y2={209.861}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1EDFF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={153.465}
          y1={51.914}
          x2={153.465}
          y2={169.98}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#C8CED7" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={153.466}
          y1={169.359}
          x2={153.466}
          y2={181.166}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9CA3AF" />
          <stop offset={1} stopColor="#9CA3AF" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="g"
          x1={153.466}
          y1={184.895}
          x2={153.466}
          y2={194.216}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E6E8" />
          <stop offset={1} stopColor="#D1D5DB" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={57.176}
          y1={46.539}
          x2={57.239}
          y2={104.635}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78AAFD" />
          <stop offset={0.327} stopColor="#BFDBFE" />
          <stop offset={1} stopColor="#A4BCE3" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={254.19}
          y1={101.359}
          x2={254.19}
          y2={154.179}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78AAFD" />
          <stop offset={0.384} stopColor="#BFDBFE" />
          <stop offset={1} stopColor="#A8C0E7" />
        </linearGradient>
        <radialGradient
          id="e"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 163.118 -255.274 0 153.465 110.947)"
        >
          <stop offset={0.239} stopColor="#F9FAFF" />
          <stop offset={1} stopColor="#A8AFC6" />
        </radialGradient>
        <filter
          id="a"
          x={107.033}
          y={44.831}
          width={71.953}
          height={12.563}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={1.999}
            result="effect1_foregroundBlur_1133_36263"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default SvgApiIllustration;
